
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { TabItemContext } from '@/components/ui/tabs/tabContext';

@Component
export default class TabValidationAwareMixin extends Vue {
    @Inject({ default: null })
    tabItemContext!: TabItemContext|null;

   setParentTabItemErrorState(valid: boolean) {
       if (this.tabItemContext) {
           this.tabItemContext.setErrorState(valid);
       }
   }
}
