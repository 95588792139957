
































import PlaceholderOptions, {
    checkboxPlaceholderOptions,
    defaultPlaceholderOptions, textareaPlaceholderOptions,
} from '@/components/form/placeholders';

import { Component, Vue, Prop } from 'vue-property-decorator';
import { FormChildType } from '@/components/form/serverForm';

@Component
export default class FServerFormChildPlaceholders extends Vue {
    @Prop({ default: null })
    type!: FormChildType|null;

    @Prop({ type: Number, default: 1 })
    amount: number;

    @Prop({ type: Boolean, default: false })
    hideLabel!: boolean

    @Prop({ type: Object, default: () => defaultPlaceholderOptions })
    defaultOptions!: PlaceholderOptions

    @Prop({ default: null })
    childPlaceholderMap!: Partial<Record<FormChildType, PlaceholderOptions>>|null;

    @Prop({ type: Boolean, default: false })
    disablePaddingTop!: boolean;

    @Prop({ type: Boolean, default: false })
    disablePaddingBottom!: boolean;

    get placeholderOptions(): PlaceholderOptions {
        if (this.type) {
            return this.getPlaceholderOptionsForChildType(this.type);
        }

        return this.defaultOptions;
    }

    getPlaceholderOptionsForChildType(childType: FormChildType) {
        if (this.childPlaceholderMap && this.childPlaceholderMap[childType]) {
            return this.childPlaceholderMap[childType]!;
        }

        switch (childType) {
            case FormChildType.CHECKBOX:
                return checkboxPlaceholderOptions;
            case FormChildType.TEXT_AREA:
                return textareaPlaceholderOptions;
            default:
                return defaultPlaceholderOptions;
        }
    }
}
