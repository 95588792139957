
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { FormChildren, FServerFormContext, ServerFormModel } from '@/components/form/serverForm';
import { UIChangeTrigger } from '@/components/form/UIChangeTrigger';
import { FormChildTriggerEvent, ValidationChangedEvent } from '@/components/form/f-server-form.vue';

@Component
export default class FormAwareMixin extends Vue {
    @Inject()
    formContext: FServerFormContext;

    get formIsInitialized(): boolean {
        return this.formContext?.initialized() || false;
    }

    onFormInitialized(callback: () => void) {
        this.formContext.onInitialized(callback);
    }

    onFormFieldValidationChange(field: string, callback: (event: ValidationChangedEvent) => void) {
        this.formContext.onFormFieldValidationChange(field, callback);
    }

    onFormFieldTrigger<K extends string = string>(field: K|FormChildren, callback: (event: FormChildTriggerEvent) => void) {
        this.formContext.onFormFieldTrigger(field, callback);
    }

    reloadForm(): Promise<void> {
        return this.formContext.reloadForm();
    }

    getParentFormModel<T extends string = string>(): ServerFormModel<T> {
        return this.formContext.getFormModel<T>();
    }

    /**
     * @param field
     * The child object you want the UiChangeTrigger to trigger for.
     * Or the id of the child you want to trigger without the form prefix.
     * For collections the id syntax is "{collectionPropertyName}_{rowNumber}_{propertyName}"
     * @example reservations_1_primaryUnitQuantity
     * @param trigger The type of trigger
     */
    triggerFormChildChange<K extends string = string>(field: K|FormChildren, trigger: UIChangeTrigger): void {
        this.formContext.triggerFormChildChange(field, trigger);
    }
}
