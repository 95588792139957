




















































import FFormItem from '@/components/form/f-form-item.vue';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { TranslateMixin } from '@/util/translate';
import { ValidationProvider } from 'vee-validate';
import { DateTime } from 'luxon';
import FormElementMixin from '@/components/form/FormElementMixin.vue';
import FFormHelpText from '@/components/form/f-form-help-text.vue';

declare global {
    interface DatepickerCustomFormatOptions {
        toDisplay?(date: Date | string, format: string, language: string): string;

        toValue?(date: Date | string, format: string, language: string): Date;
    }
}

@Component({
    components: { ValidationProvider, FFormItem, FFormHelpText }
})
export default class FDatepicker extends Mixins(FormElementMixin, TranslateMixin) {
    @Ref()
    input!: HTMLInputElement;

    @Prop({ type: String, required: false, default: null })
    pattern!: string|null;

    @Prop({ type: Boolean, default: false })
    valueAsDateTime!: boolean;

    @Prop({ type: [String, Object, Date], default: null })
    value!: string | DateTime | Date | null;

    @Prop({ type: Boolean, default: true })
    showLabel: boolean;

    @Prop({ type: Date, default: null })
    endDate!: Date|null;

    innerValue: DateTime | null = null;

    manualInput = false;

    get inputWrapperClasses(): string {
        if (this.$slots.prepend || this.$slots.append) {
            return 'input-group';
        }

        return '';
    }

    get displayValue(): string {
        if (this.innerValue == null) {
            return '';
        }

        return this.innerValue.toFormat('dd-MM-yyyy');
    }

    beforeMount() {
        this.updateInnerValue();
    }

    getInputElement() {
        return this.input;
    }

    updateInnerValue() {
        if (typeof this.value == 'string' && this.value.length) {
            this.innerValue = DateTime.fromISO(this.value);

            if(this.innerValue.isValid === false) {
                this.innerValue = DateTime.fromFormat(this.value, 'dd-MM-yyyy');
            }
        }

        if (this.value instanceof DateTime) {
            this.innerValue = this.value;
        }
    }

    mounted() {
        const pattern = this.pattern;

        const options = {
            format: {
                toDisplay(date: Date, format: string, language: string) {
                    if (pattern !== null) return DateTime.fromJSDate(date).toFormat(pattern);

                    return DateTime.fromJSDate(date).toISODate();
                },
                toValue(date: string, format: string, language: string) {
                    let parsedDate: DateTime;
                    if (pattern !== null) {
                        parsedDate = DateTime.fromFormat(date, pattern);
                    } else {
                        parsedDate = DateTime.fromISO(date);
                    }

                    if (!parsedDate.isValid) {
                        parsedDate = DateTime.now();
                    }

                    return parsedDate.toUTC().toJSDate();
                },
            } as DatepickerCustomFormatOptions,
            autoclose: true,
            todayHighlight: true,
            clearBtn: true
        }

        if (this.endDate) {
            options['endDate'] = this.endDate;
        }

        $(this.input).datepicker(options)
        .on('changeDate', (e) => {
            this.innerValue = e.date ? DateTime.fromJSDate(e.date) : null;
        })
        .on('clearDate', (e) => {
            this.innerValue = null;
        });
    }

    startManualInput() {
        this.manualInput = true;
        this.$nextTick(() => {
            this.input.value = '';
            this.input.focus();
        });
    }

    reset() {
        this.innerValue = null;
    }

    @Watch('value')
    modelValueChanged() {
        this.updateInnerValue();
    }

    @Watch('innerValue')
    onInnerValueChanged(newVal: DateTime|null): void {
        $(this.input).datepicker('update', newVal?.toUTC().toJSDate());

        if (this.valueAsDateTime) {
            this.$emit('input', newVal);
        } else {
            this.$emit('input', newVal?.toISODate() ?? null);
        }
    }
}
