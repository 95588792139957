interface BlockSize {
    height: string,
    width: string
}

interface PlaceholderOptions {
    inputSize: BlockSize|false
    labelSize: BlockSize|false
}

const defaultPlaceholderOptions: PlaceholderOptions = Object.freeze({
    inputSize: {
        height: '37px',
        width: '100%'
    },
    labelSize: {
        height: '22px',
        width: '120px'
    }
});

const checkboxPlaceholderOptions: PlaceholderOptions = Object.freeze({
    inputSize: {
        height: '23px',
        width: '450px'
    },
    labelSize: false
});

const textareaPlaceholderOptions: PlaceholderOptions = Object.freeze({
    inputSize: {
        height: '78px',
        width: '100%'
    },
    labelSize: defaultPlaceholderOptions.labelSize
});

const terminalPlaceholderOptions: PlaceholderOptions = Object.freeze({
    inputSize: {
        height: '55px',
        width: '100%'
    },
    labelSize: {
        height: '26px',
        width: '120px'
    }
});

export default PlaceholderOptions;
export { defaultPlaceholderOptions, terminalPlaceholderOptions, checkboxPlaceholderOptions, textareaPlaceholderOptions };
