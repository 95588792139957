






























import { Component, Prop, Mixins, Watch, Ref } from 'vue-property-decorator';
import { TranslateMixin } from '@/util/translate';
import { ValidationProvider } from 'vee-validate';
import FFormItem from '@/components/form/f-form-item.vue';

@Component({
    components: { ValidationProvider, FFormItem }
})
export default class FFile extends Mixins(TranslateMixin) {
    @Ref()
    input!: HTMLInputElement;

    @Prop({ type: String })
    vid: string;

    @Prop({ type: [Object, String ] })
    rules: string | object;

    getInputElement() {
        return this.input;
    }

    value: FileList|string;

    innerValue = false;

    mounted() {

    }

    emitEvent(event) {
        this.$emit('input', event.target.files);
    }
}
