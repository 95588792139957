var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("validation-provider", {
    attrs: {
      tag: "div",
      vid: _vm.vid,
      name: _vm.translate(_vm.label),
      rules: _vm.rules
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(_ref) {
            var errors = _ref.errors
            return _c(
              "f-form-item",
              {
                attrs: {
                  "error-messages": errors,
                  label: _vm.label,
                  required: _vm.required,
                  "show-label": _vm.showLabel,
                  "no-space": _vm.$attrs["no-space"]
                }
              },
              [
                _c(
                  "div",
                  {
                    class: _vm.inputWrapperClasses
                  },
                  [
                    _vm._t("prepend"),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.manualInput,
                          expression: "!manualInput"
                        }
                      ],
                      ref: "displayInput",
                      staticClass: "form-control",
                      class: _vm.inputClass,
                      attrs: {
                        id: _vm.id,
                        type: "text",
                        disabled: _vm.$attrs.disabled
                      },
                      domProps: {
                        value: _vm.displayValue
                      },
                      on: {
                        click: _vm.startManualInput
                      }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.manualInput,
                          expression: "manualInput"
                        }
                      ],
                      ref: "input",
                      staticClass: "form-control",
                      class: _vm.inputClass,
                      attrs: {
                        type: "text",
                        disabled: _vm.$attrs.disabled
                      },
                      on: {
                        keydown: [
                          function($event) {
                            $event.stopPropagation()
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            _vm.manualInput = false
                          }
                        ],
                        input: function($event) {
                          $event.stopPropagation()
                        },
                        blur: function($event) {
                          _vm.manualInput = false
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm._t("append")
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.helpText
                  ? _c("f-form-help-text", {
                      attrs: {
                        text: _vm.helpText,
                        icon: _vm.helpIcon
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm._t("below-input")
              ],
              2
            )
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }