var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "form-loading",
      style: {
        paddingBottom:
          _vm.amount === 1 && !_vm.disablePaddingBottom ? "0.5rem" : null,
        paddingTop: _vm.amount === 1 && !_vm.disablePaddingTop ? "0.5rem" : null
      }
    },
    [
      _c(
        "content-placeholders",
        [
          _vm._l(_vm.amount, function(i) {
            return [
              _vm.placeholderOptions.labelSize && !_vm.hideLabel
                ? _c(
                    "div",
                    {
                      key: "form-loader-label-" + i,
                      staticClass: "form-loading-label",
                      style: _vm.placeholderOptions.labelSize
                    },
                    [
                      _c("content-placeholders-text", {
                        attrs: {
                          lines: 1
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  key: "form-loader-input-" + i,
                  staticClass: "form-loading-input",
                  style: _vm.placeholderOptions.inputSize
                },
                [
                  _c("content-placeholders-text", {
                    attrs: {
                      lines: 1
                    }
                  })
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }