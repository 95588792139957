




























import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { TranslateMixin } from '@/util/translate';
import { ValidationProvider } from 'vee-validate';
import FFormItem from '@/components/form/f-form-item.vue';
import KeyUpEvent = JQuery.KeyUpEvent;
import FormElementMixin from '@/components/form/FormElementMixin.vue';
import FFormHelpText from '@/components/form/f-form-help-text.vue';

@Component({
    components: { ValidationProvider, FFormItem, FFormHelpText }
})
export default class FTextArea extends Mixins(FormElementMixin, TranslateMixin) {
    @Ref()
    textarea!: HTMLTextAreaElement;

    @Prop()
    value: any;

    @Prop({ required: false, type: Boolean, default: true })
    showLabel!: boolean;

    innerValue?: string = '';

    @Watch('innerValue')
    onInnerValueChanged(newVal): void {
        this.$emit('input', newVal);
        this.$emit('change', newVal);
    }

    @Watch('value')
    onValueChanged(newVal): void {
        this.innerValue = newVal;
    }

    getInputElement() {
        return this.textarea;
    }

    onKeyUp(evt: KeyUpEvent): void {
        this.$emit('keyup', evt);
    }

    mounted(): void {
        this.innerValue = this.value;
    }
}
