enum UIChangeTrigger {
    SHOW_INDICATOR,
}

export default class UIChangeTriggerController {
    showChangeIndicator = false;
    showChangeIndicatorTimeout = 0;

    constructor() {
    }

    handleTrigger(trigger: UIChangeTrigger) {
        switch(trigger) {
            case UIChangeTrigger.SHOW_INDICATOR:
                window.clearTimeout(this.showChangeIndicatorTimeout);
                this.showChangeIndicator = true;
                this.showChangeIndicatorTimeout = window.setTimeout(() => {
                    this.showChangeIndicator = false;
                }, 750);
                return;
        }
    }

    getElementAttributes() {
        return {
            'class': {
                'ui-change-show-indicator' : this.showChangeIndicator
            }
        };
    }

    getFormChildProps() {
        const attributes = this.getElementAttributes();

        return {
            inputClass: attributes.class,
        };
    }
}

export {
    UIChangeTrigger
};
