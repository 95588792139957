var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.formIsInitialized
    ? _c(
        _vm.formComponent,
        _vm._b(
          {
            ref: "formElement",
            tag: "component",
            on: {
              input: _vm.onValueChange,
              "change-object": _vm.changeObject,
              "load-lazy-choices": _vm.loadLazyChoices
            },
            scopedSlots: _vm._u(
              [
                _vm.$slots["below-input"]
                  ? {
                      key: "below-input",
                      fn: function() {
                        return [_vm._t("below-input")]
                      },
                      proxy: true
                    }
                  : null
              ],
              null,
              true
            ),
            model: {
              value: _vm.innerValue,
              callback: function($$v) {
                _vm.innerValue = $$v
              },
              expression: "innerValue"
            }
          },
          "component",
          _vm.componentProps,
          false
        )
      )
    : !_vm.formContext.placeholdersAreDisabled()
    ? _c(
        "div",
        [
          _c("f-server-form-child-placeholders", {
            attrs: {
              "default-options": _vm.getDefaultPlaceholderOptions(),
              "hide-label": _vm.hideLabel,
              type: _vm.type
            }
          }),
          _vm._v(" "),
          _vm._t("below-input")
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }